import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "virtual:uno.css";
import "normalize.css";
import * as Sentry from "@sentry/react";

import { Client } from "./Client";
import SearchCouponByDistancePage from "./components/pages/searchCouponByDistancePage";
import RecommendPage from "./components/pages/recommendPage";
import FavoriteCouponPage from "./components/pages/favoriteCouponPage";
import CouponDetailPage from "./components/pages/couponDetailPage";
import TransactionPage from "./components/pages/transactionPage";
import { initializeSDK } from "./lib/sdk";
import RequestPermissionLayout from "./components/templates/requestPermissionLayout";
import ShopListPage from "./components/pages/shopListPage";
import CreateAndGetUserLayout from "./components/templates/createAndGetUserLayout";
import SettingsPage from "./components/pages/settingsPage";
import ShopDetailPage from "./components/pages/shopDetailPage";
import Loading from "./components/organisms/loading";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RequestPermissionLayout />,
    children: [
      {
        path: "/",
        element: <CreateAndGetUserLayout />,
        children: [
          {
            path: "/",
            element: <SearchCouponByDistancePage />,
          },
          {
            path: "/coupons/recommend",
            element: <RecommendPage />,
          },
          {
            path: "/coupons/favorite",
            element: <FavoriteCouponPage />,
          },
          {
            path: "/coupons/:couponID",
            element: <CouponDetailPage />,
          },
          {
            path: "/transactions/:slug",
            element: <TransactionPage />,
          },
          {
            path: "/shops",
            element: <ShopListPage />,
          },
          {
            path: "/shops/:shopID",
            element: <ShopDetailPage />,
          },
          {
            path: "/settings",
            element: <SettingsPage />,
          },
        ],
      },
    ],
  },
]);

const baseUrl = import.meta.env.VITE_BACKEND_URL;

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initSDK = async () => {
      await initializeSDK();
      setIsLoading(false);
    };
    initSDK();
  }, []);

  if (isLoading) {
    // ローディング画面を表示
    return (
      <div className="min-h-screen flex justify-center items-center bg-#FAF6EF">
        <Loading></Loading>
      </div>
    );
  }

  return (
    <Client baseUrl={baseUrl}>
      <RouterProvider router={router} />
    </Client>
  );
};

Sentry.init({
  dsn: "https://f46e92617a4cf36787b2f48586a6b24c@o4504292631707648.ingest.us.sentry.io/4508415921094656",
  integrations: [],
  environment: import.meta.env.VITE_APP_ENV,
  enabled: import.meta.env.VITE_APP_ENV !== "local",
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
