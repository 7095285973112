import { useMutation, useQuery } from "@tanstack/react-query";
import {
  CreateAndGetUserRequest,
  UseCouponRequest,
} from "schema/gen/es/foodlosscoupon/app/v1/app_pb";

import { api } from "../lib/api";
import { PartialMessage } from "@bufbuild/protobuf";

export function useQueryGetCouponsByTenantID() {
  return useQuery({
    queryKey: ["getCouponsByTenantID"],
    queryFn: () => api.getCouponsByTenantID({}),
  });
}

export function useQueryGetTenant() {
  return useQuery({
    queryKey: ["getTenant"],
    queryFn: () => api.getTenant({}),
  });
}

export function useQueryGetTenantMessage() {
  return useQuery({
    queryKey: ["getTenantMessage"],
    queryFn: () => api.getTenantMessage({}),
  });
}

export function useQueryGetDistributedCouponsByTenantIDSortedByDistance() {
  return useQuery({
    queryKey: ["getDistributedCouponsByTenantIDSortedByDistance"],
    queryFn: () => api.getDistributedCouponsByTenantIDSortedByDistance({}),
  });
}

export function useQueryGetRecommendCouponsByTenantID() {
  return useQuery({
    queryKey: ["getRecommendCouponsByTenantID"],
    queryFn: () => api.getRecommendCouponsByTenantID({}),
  });
}

export function useQueryGetCouponByID(id: string) {
  return useQuery({
    queryKey: ["getCouponByID", id],
    queryFn: () => api.getCouponByID({ id }),
  });
}

export function useQueryGetShopsByTenantIDSortedByDistance() {
  return useQuery({
    queryKey: ["getShopsByTenantIDSortedByDistance"],
    queryFn: () => api.getShopsByTenantIDSortedByDistance({}),
  });
}

export function useQueryGetShopByID(id: string) {
  return useQuery({
    queryKey: ["getShopByID", id],
    queryFn: () => api.getShopByID({ id }),
  });
}

export function useMutationUseCoupon() {
  return useMutation({
    mutationKey: ["useCoupon"],
    mutationFn: (args: PartialMessage<UseCouponRequest>) => api.useCoupon(args),
  });
}

export function useMutationCreateAndGetUser() {
  return useMutation({
    mutationKey: ["createAndGetUser"],
    mutationFn: (args: PartialMessage<CreateAndGetUserRequest>) =>
      api.createAndGetUser(args),
  });
}
