import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryGetRecommendCouponsByTenantID } from "../..//hooks/useQuery";

import SearchSelectionBar from "../organisms/searchSelectionBar";
import Footer from "../organisms/footer";
import Header from "../organisms/header";
import Loading from "../organisms/loading";

const RecommendPage: FC = () => {
  const navigate = useNavigate();
  const {
    data: getRecommendCouponsByTenantID,
    isLoading,
    error,
  } = useQueryGetRecommendCouponsByTenantID();

  // tokenの有効期限が切れた場合に強制リロードする
  useEffect(() => {
    if (error) {
      location.reload();
    }
  }, [error]);

  if (isLoading) {
    // ローディング画面を表示
    return (
      <div className="min-h-screen flex justify-center items-center bg-#FAF6EF">
        <Loading></Loading>
      </div>
    );
  }

  return (
    <>
      <div className="min-h-screen bg-#FAF6EF font-hiragino">
        <Header title="クーポン" isShowBackArrow={false}></Header>
        <SearchSelectionBar searchSelection={"recommend"} />
        <div className="flex flex-col gap-16px">
          <div className="flex flex-wrap justify-start gap-16px w-full max-w-[390px] box-border mx-auto px-16px pb-200px">
            {getRecommendCouponsByTenantID?.coupons.length == 0 ? (
              <div className="py-48px w-full">
                <div className="flex justify-center ">
                  <span className="material-symbols-rounded text-[80px] text-#9CA3AF80">
                    confirmation_number
                  </span>
                </div>
                <div className="text-14px text-tertiary text-center leading-5">
                  配布中のクーポンはありません
                </div>
              </div>
            ) : (
              getRecommendCouponsByTenantID?.coupons.map((coupon) => {
                return (
                  <>
                    <div
                      onClick={() => {
                        navigate(`/coupons/${coupon.coupon?.id}`);
                      }}
                      className="relative h-196px bg-#FFF rounded-12px shadow-DropShadowHight"
                      style={{
                        width: "calc((100% - 16px) / 2)",
                      }}
                    >
                      <div className="flex justify-end w-full h-26px p-4px absolute">
                        <div className="ml-auto mr-10px inline-block h-18px px-8px py-4px bg-#FFF rounded-20px border-solid border-1.5px border-#F39828 text-12px text-center font-bold text-#F39828 font-hiragino leading-150">
                          {coupon.distance >= 1000
                            ? `+${
                                (Number(coupon.distance) / 1000) % 1 === 0
                                  ? (Number(coupon.distance) / 1000).toFixed(0) // 小数点第一位が0なら整数表示
                                  : (Number(coupon.distance) / 1000).toFixed(1)
                              }km` // それ以外は小数点第一位まで表示
                            : `+${Math.floor(Number(coupon.distance) / 100) * 100}m`}
                        </div>
                      </div>
                      <div className="h-120px">
                        <img
                          src={`${location.origin}/storage/${coupon.coupon?.imagePath}`}
                          alt={coupon.coupon?.couponName}
                          className="w-full h-120px object-cover rounded-t-12px" // 画像を全体に表示し、オブジェクトカバーでサイズに応じてトリミング
                          loading="lazy"
                        />
                      </div>
                      <div className="flex flex-col gap-6px w-151px h-60px px-10px py-8px">
                        <div className="h-24px text-16px text-#111827 font-hiragino truncate">
                          {coupon.coupon?.couponName}
                        </div>
                        <div>
                          <span className="inline-block h-21px px-8px py-4px bg-#E9573E rounded-8px text-14px text-#FFF font-semibold leading-5.5 font-hiragino">
                            {coupon.coupon?.discountMethod ==
                            "percentage_discount"
                              ? coupon.coupon?.discountValue + "%OFF"
                              : coupon.coupon?.discountValue + "円引き"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            )}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default RecommendPage;
