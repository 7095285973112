import { FC, useEffect, useState } from "react";
import confetti from "canvas-confetti";
import { useParams } from "react-router-dom";
import {
  useMutationUseCoupon,
  useQueryGetCouponByID,
} from "../../hooks/useQuery";
import { useNavigate } from "react-router-dom";
import Header from "../organisms/header";
import { Timestamp } from "@bufbuild/protobuf";
import { formatTimestamp } from "../../utils/date";
import Loading from "../organisms/loading";

const TransactionPage: FC = () => {
  const [count, setCount] = useState(1);
  const [savedCouponID, setSavedCouponID] = useState<string | null>(null);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const navigate = useNavigate();
  const [isCardDark, setIsCardDark] = useState(false);
  const useCoupon = useMutationUseCoupon();
  const [isNotDistributed, setIsNotDistributed] = useState(false);
  const [isIdMismatch, setIsIdMismatch] = useState(false);
  const { slug } = useParams<{ slug: string }>();

  const handleTransactSubmit = () => {
    useCoupon
      .mutateAsync({
        couponId: savedCouponID || "",
        usageCount: BigInt(count),
      })
      .then(() => {
        setIsOpenConfirmation(false);
        setIsComplete(true);
        setIsCardDark(true);
        sessionStorage.removeItem("couponID");
      })
      .catch(() => {
        alert("エラーが発生しました。このダイアログを閉じた後、もう一度「クーポンを利用する」から操作を行なってください。");
        location.reload(); 
      });
  };

  const {
    data: getCouponByID,
    isLoading: isQueryLoading,
    error,
  } = useQueryGetCouponByID(savedCouponID || "");

  const handleIncrement = () => {
    setCount((prevCount) => prevCount + 1);
  };

  const handleDecrement = () => {
    setCount((prevCount) => (prevCount > 1 ? prevCount - 1 : prevCount));
  };

  const calculateDiscountedPrice = (): string | null => {
    const originalPriceNumber = parseFloat(
      String(getCouponByID?.coupon?.originalPrice)
    );
    const discountValueNumber = parseFloat(
      String(getCouponByID?.coupon?.discountValue)
    );

    if (isNaN(originalPriceNumber) || isNaN(discountValueNumber)) {
      return null;
    }

    if (getCouponByID?.coupon?.discountMethod === "percentage_discount") {
      const discountedPrice =
        originalPriceNumber * (1 - discountValueNumber / 100);
      return discountedPrice.toFixed(0); // 小数点以下を切り捨てて整数値に
    } else if (
      getCouponByID?.coupon?.discountMethod === "fixed_amount_discount"
    ) {
      const discountedPrice = originalPriceNumber - discountValueNumber;
      return discountedPrice.toFixed(0); // 小数点以下を切り捨てて整数値に
    }

    return null;
  };

  useEffect(() => {
    // セッションストレージからクーポンIDを取得
    const couponID = sessionStorage.getItem("couponID");
    if (!couponID) {
      navigate("/");
    }
    setSavedCouponID(couponID); // クーポンIDをステートに保存
  }, []);

  useEffect(() => {
    setIsNotDistributed(false);
    if (!getCouponByID?.coupon?.isDistributed) {
      setIsNotDistributed(true);
    }
    if (
      formatTimestamp(getCouponByID?.coupon?.distributionEndAt as Timestamp)
    ) {
      const distributionEndDate = (
        getCouponByID?.coupon?.distributionEndAt as Timestamp
      ).toDate();
      const currentDate = new Date();
      if (currentDate > distributionEndDate) {
        setIsNotDistributed(true);
      }
    }
    if (
      formatTimestamp(getCouponByID?.coupon?.distributionStartAt as Timestamp)
    ) {
      const distributionStartAt = (
        getCouponByID?.coupon?.distributionStartAt as Timestamp
      ).toDate();
      const currentDate = new Date();
      if (currentDate < distributionStartAt) {
        setIsNotDistributed(true);
      }
    }

    if (getCouponByID && slug && getCouponByID.shop?.slug !== slug) {
      setIsIdMismatch(true);
    }
  }, [getCouponByID, slug]);

  // tokenの有効期限が切れた場合に強制リロードする
  useEffect(() => {
    if (error) {
      location.reload();
    }
  }, [error]);

  if (isQueryLoading) {
    // ローディング画面を表示
    return (
      <div className="min-h-screen flex justify-center items-center bg-#FAF6EF">
        <Loading></Loading>
      </div>
    );
  }

  if (isIdMismatch) {
    return (
      <div className="min-h-screen bg-#FAF6EF font-hiragino">
        <Header title="" isShowBackArrow={false}></Header>
        <div className="flex flex-col gap-16px p-12px">
          <div className="flex flex-col gap-8px p-16px rounded-12px bg-errorLight">
            <div className="flex gap-4px">
              <div>
                <span className="material-symbols-rounded  text-[24px] text-#B91C1C">
                  dangerous
                </span>
              </div>
              <div className="text-16px text-#111827 font-hiragino leading-6">
                ショップが異なります
              </div>
            </div>
            <div className="text-14px text-secondary font-hiragino leading-5">
              こちらのクーポンはこちらのショップではご利用いただけません。クーポンの配布ショップが正しいかご確認ください。
            </div>
          </div>
          <button
            onClick={() => {
              sessionStorage.removeItem("couponID");
              navigate("/");
            }}
            className="block w-full  h-56px bg-#1BA368 rounded-16px border-none shadow-ButtonShadow text-16px font-semibold text-#FFF font-hiragino"
          >
            ホームへ戻る
          </button>
        </div>
      </div>
    );
  }

  if (isNotDistributed) {
    return (
      <div className="min-h-screen bg-#FAF6EF font-hiragino">
        <Header
          title=""
          isShowBackArrow={false}
          isTransactionPage={true}
        ></Header>
        <div className="flex flex-col gap-16px p-12px">
          <div className="flex flex-col gap-8px p-16px rounded-12px bg-errorLight">
            <div className="flex gap-4px">
              <div>
                <span className="material-symbols-rounded  text-[24px] text-#B91C1C">
                  dangerous
                </span>
              </div>
              <div className="text-16px text-#111827 font-hiragino leading-6">
                クーポンの配布期間外です
              </div>
            </div>
            <div className="text-14px text-secondary font-hiragino leading-5">
              申し訳ありませんがホームへ戻って有効なクーポンをご利用ください。
            </div>
          </div>
          <button
            onClick={() => {
              sessionStorage.removeItem("couponID");
              navigate("/");
            }}
            className="block w-full  h-56px bg-#1BA368 rounded-16px border-none shadow-ButtonShadow text-16px font-semibold text-#FFF font-hiragino"
          >
            ホームへ戻る
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="min-h-screen bg-#FAF6EF font-hiragino">
        <Header
          title="クーポン利用"
          isShowBackArrow={true}
          isTransactionPage={true}
        ></Header>
        <div className="overflow-hidden min-h-screen bg-#FAF6EF px-16px">
          <div className="flex flex-col gap-16px box-border w-full max-w-358px py-12px mx-auto bg-#FAF6EF">
            <div className="scale-x-[-1] scale-y-[-1] w-59px h-24px px-16px py-8px truncate rounded-8px bg-#FFF text-16px text-#111827 font-hiragino leading-6">
              {count}枚利用
            </div>
            <div className="flex relative scale-x-[-1] scale-y-[-1]">
              <div className="flex flex-col z-20 gap-16px flex-grow h-162px bg-[linear-gradient(0deg,#F1F5F9_0%,#FFF_100%)] rounded-l-16px p-16px shadow-ReverseDropShadowHight">
                <div>
                  <div className="flex gap-8px">
                    <div className="text-28px text-#B91C1C font-semibold font-hiragino leading-8">
                      -
                      {getCouponByID?.coupon?.discountMethod ==
                      "percentage_discount"
                        ? getCouponByID?.coupon?.discountValue + "%"
                        : getCouponByID?.coupon?.discountValue + "円"}
                    </div>
                    {getCouponByID?.coupon?.isSetOriginalPrice && (
                      <div className="flex gap-8px">
                        <div className="flex">
                          <div className="text-28px text-#111827 font-semibold font-hiragino leading-8">
                            {calculateDiscountedPrice()}
                          </div>
                          <div className="text-20px text-#111827 font-semibold font-hiragino leading-9">
                            円
                          </div>
                        </div>
                        <div className="text-16px text-#111827 font-hiragino leading-10">
                          税込
                        </div>
                      </div>
                    )}
                  </div>
                  {getCouponByID?.coupon?.isSetOriginalPrice && (
                    <div className="text-16px text-#111827 line-through font-hiragino leading-7">
                      (定価{String(getCouponByID?.coupon?.originalPrice)}円)
                    </div>
                  )}
                </div>
                <div className="flex justify-between">
                  <div className="flex flex-col justify-between  h-73px">
                    <div className="text-16px font-semibold text-#111827 font-hiragino leading-6">
                      {getCouponByID?.coupon?.couponName}
                    </div>
                    <div className="text-14px text-#111827 font-hiragino leading-4">
                      {getCouponByID?.shop?.name}
                    </div>
                  </div>
                  <div className="w-103px h-73px rounded-8px">
                    <img
                      src={`${location.origin}/storage/${getCouponByID?.coupon?.imagePath}`}
                      alt={getCouponByID?.coupon?.couponName}
                      className="w-103px h-73px rounded-8px"
                    />
                  </div>
                </div>
              </div>
              <div className="z-20 w-25px h-80px pl-16px pr-17px py-57px bg-gradient-to-b from-[#FDBA74] to-[#F97316] rounded-r-16px">
                <div className="scale-x-[-1] scale-y-[-1] text-sideways text-20px text-#FFF font-hiragino">
                  クーポン
                </div>
              </div>
              {
                // 取引完了後のアニメーション
              }
              <>
                <div
                  style={{ transform: "rotate(20deg)" }}
                  className={`${
                    isCardDark
                      ? "transition-all duration-500 ease-in-out transform scale-200 w-106px h-106px border-solid border-10px border-#FFF rounded-full text-center leading-25 z-40 animate-fadeIn absolute top-1/5 left-1/3 text-64px text-#FFF font-hiraginoMaru"
                      : "absolute z-10 opacity-0 w-200px h-200px text-100px"
                  }`}
                >
                  済
                </div>
                <div
                  className={`${isCardDark ? "z-30 transition-opacity duration-500 absolute top-0 left-0 opacity-20 w-full h-full bg-black rounded-16px" : "opacity-0"}`}
                ></div>
              </>
            </div>
            <div className="flex relative">
              <div className="flex flex-col z-20 gap-16px flex-grow h-162px bg-[linear-gradient(0deg,#F1F5F9_0%,#FFF_100%)] rounded-l-16px p-16px shadow-DropShadowHight">
                <div>
                  <div className="flex gap-8px">
                    <div className="text-28px text-#B91C1C font-semibold font-hiragino leading-8">
                      -
                      {getCouponByID?.coupon?.discountMethod ==
                      "percentage_discount"
                        ? getCouponByID?.coupon?.discountValue + "%"
                        : getCouponByID?.coupon?.discountValue + "円"}
                    </div>
                    {getCouponByID?.coupon?.isSetOriginalPrice && (
                      <div className="flex gap-8px">
                        <div className="flex">
                          <div className="text-28px text-#111827 font-semibold font-hiragino leading-8">
                            {calculateDiscountedPrice()}
                          </div>
                          <div className="text-20px text-#111827 font-semibold font-hiragino leading-9">
                            円
                          </div>
                        </div>
                        <div className="text-16px text-#111827 font-hiragino leading-10">
                          税込
                        </div>
                      </div>
                    )}
                  </div>
                  {getCouponByID?.coupon?.isSetOriginalPrice && (
                    <div className="text-16px text-#111827 line-through font-hiragino leading-7">
                      (定価{String(getCouponByID?.coupon?.originalPrice)}円)
                    </div>
                  )}
                </div>
                <div className="flex justify-between">
                  <div className="flex flex-col justify-between  h-73px">
                    <div className="text-16px font-semibold text-#111827 font-hiragino leading-6">
                      {getCouponByID?.coupon?.couponName}
                    </div>
                    <div className="text-14px text-#111827 font-hiragino leading-4">
                      {getCouponByID?.shop?.name}
                    </div>
                  </div>
                  <div className="w-103px h-73px rounded-8px">
                    <img
                      src={`${location.origin}/storage/${getCouponByID?.coupon?.imagePath}`}
                      alt={getCouponByID?.coupon?.couponName}
                      className="w-103px h-73px rounded-8px"
                    />
                  </div>
                </div>
              </div>
              <div className="z-20 w-25px h-80px pl-16px pr-17px py-57px bg-gradient-to-b from-[#FDBA74] to-[#F97316] rounded-r-16px">
                <div className="scale-x-[-1] scale-y-[-1] text-sideways text-20px text-#FFF font-hiragino">
                  クーポン
                </div>
              </div>
              {
                // 取引完了後のアニメーション
              }
              <>
                <div
                  style={{ transform: "rotate(20deg)" }}
                  className={`${
                    isCardDark
                      ? "transition-all duration-500 ease-in-out transform scale-200 w-106px h-106px border-solid border-10px border-#FFF rounded-full text-center leading-25 z-40 animate-fadeIn absolute top-1/5 left-1/3 text-64px text-#FFF font-hiraginoMaru"
                      : "absolute z-10 opacity-0 w-200px h-200px text-100px"
                  }`}
                >
                  済
                </div>
                <div
                  className={`${isCardDark ? "z-30 transition-opacity duration-500 absolute top-0 left-0 opacity-20 w-full h-full bg-black rounded-16px" : "opacity-0"}`}
                ></div>
              </>
            </div>
            {isComplete ? (
              <div className="flex justify-end">
                <div className="min-w-59px h-24px px-16px py-8px bg-#FFF rounded-8px text-16px text-#111827 leading-6.5">
                  {count}枚利用
                </div>
              </div>
            ) : (
              <div className="flex justify-around gap-16px">
                <div className="text-16px text-#111827 font-hiragino leading-12">
                  利用枚数
                </div>
                <div className="flex gap-12px">
                  <div
                    onClick={handleDecrement}
                    className="w-24px h-16px p-16px bg-#FFF rounded-12px border-solid border-1px border-lightGray"
                  >
                    <span className="material-symbols-rounded text-[24px] text-#4B556380 leading-3.5">
                      remove
                    </span>
                  </div>
                  <div className="w-64px h-16px py-16px px-18px rounded-12px bg-#FFF">
                    {count}枚
                  </div>
                  <div
                    onClick={handleIncrement}
                    className="w-24px h-16px p-16px bg-#FFF rounded-12px border-solid border-1px border-lightGray"
                  >
                    <span className="material-symbols-rounded text-[24px] text-#4B556380 leading-3.5">
                      add
                    </span>
                  </div>
                </div>
              </div>
            )}
            {isComplete ? (
              <div className="w-full h-80px p-16px box-border bg-#DDEFD8 text-center text-16px font-semibold text-#047857 font-hiragino leading-6">
                フードロス削減にご協力いただきありがとうございます！
              </div>
            ) : (
              <div className="w-full h-80px p-16px box-border bg-#DDEFD8 text-center text-16px font-semibold text-#047857 font-hiragino leading-6">
                こちらの画面を
                <br />
                ショップスタッフにお見せください
              </div>
            )}
            {isComplete ? (
              <button
                onClick={() => {
                  navigate("/");
                }}
                className="block w-full  h-56px bg-#1BA368 rounded-16px border-none shadow-ButtonShadow text-16px font-semibold text-#FFF font-hiragino"
              >
                ホームへ戻る
              </button>
            ) : (
              <button
                onClick={() => {
                  setIsOpenConfirmation(true);
                }}
                className="block w-full h-56px bg-#1BA368 rounded-16px border-none shadow-ButtonShadow text-16px font-semibold text-#FFF font-hiragino"
              >
                クーポンを利用する
              </button>
            )}
          </div>
        </div>
        {isOpenConfirmation && (
          <div
            id="modal-overlay"
            tabIndex={-1}
            aria-hidden="true"
            onClick={() => setIsOpenConfirmation(false)}
            className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-screen backdrop-blur-sm bg-surfaceOverlay"
          >
            <div
              onClick={(e) => e.stopPropagation()}
              className="fixed bottom-0 left-0 right-0 w-full h-218px"
            >
              <div className="flex flex-col items-start gap-[20px] w-full h-168px  pt-20px pb-36px  bg-white border-[1px] border-lightGray border-solid rounded-t-24px">
                <div className="mx-auto">
                  <div className="flex flex-col gap-20px">
                    <div className="text-18px text-#111827 font-semibold font-hiragino">
                      クーポンを{count}枚利用してよろしいですか？
                    </div>
                    <div className="flex flex-col gap-12px">
                      <button
                        onClick={() => {
                          handleTransactSubmit();
                          confetti({
                            particleCount: 300,
                            spread: 70,
                            origin: { y: 0.6 },
                          });
                        }}
                        className="block w-full h-56px bg-#1BA368 rounded-16px border-none shadow-ButtonShadow text-16px font-semibold text-#FFF font-hiragino"
                      >
                        OK
                      </button>
                      <button
                        onClick={() => setIsOpenConfirmation(false)}
                        className="block w-full h-56px bg-#FFF rounded-16px border-none shadow-ButtonShadow text-16px font-semibold text-#111827 font-hiragino"
                      >
                        キャンセル
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TransactionPage;
